import { FC } from 'react';
import { FAQAccordionItems } from '@components/main-page/main-page.state';
import { FAQSectionHoverColor } from '@components/shared/blocks/faq/types';
import FAQSection from '@components/shared/blocks/faq';
import styles from './index.module.scss';

type FAQSectionComponentProps = {
    title: string;
    accordionItems: FAQAccordionItems[];
    hoverColor: FAQSectionHoverColor;
}

const FAQSectionComponent: FC<FAQSectionComponentProps> = ({ accordionItems, hoverColor, title }) => {
    return (
        <div className={styles.container}>
            <FAQSection itemsFAQ={accordionItems} title={title} hoverColor={hoverColor}/>
        </div>
    )
}

export default FAQSectionComponent;
